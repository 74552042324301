import C from "./Checkbox.module.css";

import type { ReactNode } from "react";
import { Checkbox, type CheckboxProps } from "react-aria-components";

export function WCheckbox({ children, ...props }: Omit<CheckboxProps, "children"> & { children?: ReactNode }) {
    return (
        <Checkbox className={C.Checkbox} {...props}>
            {({ isIndeterminate }) => (
                <>
                    <div className={C.CheckIcon}>
                        <svg viewBox="0 0 18 18" aria-hidden="true">
                            {isIndeterminate ? (
                                <rect x={1} y={7.5} width={15} height={3} />
                            ) : (
                                <polyline points="1 9 7 14 15 4" />
                            )}
                        </svg>
                    </div>
                    {children}
                </>
            )}
        </Checkbox>
    );
}
