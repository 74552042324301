import C from "./Editable.module.css";

import { cn } from "../../utils/classNames.ts";
import { WButton, type WButtonProps } from "../button/WButton.tsx";

export interface EditableButtonProps extends Omit<WButtonProps, "variant"> {
    inAdmin?: boolean;
}

export function EditableButton({ children, icon, inAdmin, ...props }: EditableButtonProps) {
    return (
        <div className={cn(C.Editable, inAdmin && C.NoMargin)}>
            <WButton variant="editable" icon={icon ? icon : "jp-icon-edit"} iconSide="right" {...props}>
                {children}
            </WButton>
        </div>
    );
}
